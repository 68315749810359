<script lang="ts" setup>
import GModal from '../../base/GModal.vue';
import GButton from '../../base/GButton.vue';

defineProps<{
  disabledViewLive?: any;
}>();

const emit = defineEmits<{
  (e: 'view-live'): void;
  (e: 'close'): void;
}>();

const handleClose = () => {
  emit('close');
};

const handleViewLive = () => {
  emit('view-live');
};
</script>
<template>
  <g-modal
    :is-open="true"
    :hide-actions="true"
    :hide-header="true"
    :hide-overlay="true"
    :content-fixed="true"
    modal-class="min-w-[378px]"
    container-class="top-[60px] w-[378px] right-10">
    <div class="bg-dark-300 p-16">
      <div class="mb-16 flex items-center">
        <span
          ><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 18.8835C14.8325 18.8835 18.75 14.9381 18.75 10.0712C18.75 5.2043 14.8325 1.25891 10 1.25891C5.16751 1.25891 1.25 5.2043 1.25 10.0712C1.25 14.9381 5.16751 18.8835 10 18.8835ZM15.1893 7.74933C15.4348 7.50498 15.4372 7.10644 15.1945 6.85917C14.9519 6.61191 14.5562 6.60955 14.3107 6.8539L8.42558 12.7111L6.43933 10.7342C6.19381 10.4898 5.79809 10.4922 5.55546 10.7395C5.31283 10.9867 5.31516 11.3853 5.56067 11.6296L7.98624 14.0438C8.2297 14.2861 8.62142 14.2861 8.86489 14.0438L15.1893 7.74933Z"
              fill="#00C853" />
          </svg>
        </span>
        <p class="text-dark-high ml-8">Publish successfully</p>
        <span
          class="text-dark-low hover:text-dark-high ml-auto cursor-pointer p-4 transition-colors duration-200"
          @click="handleClose">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.64645 6.64645C6.84171 6.45118 7.15829 6.45118 7.35355 6.64645L17.3536 16.6464C17.5488 16.8417 17.5488 17.1583 17.3536 17.3536C17.1583 17.5488 16.8417 17.5488 16.6464 17.3536L6.64645 7.35355C6.45118 7.15829 6.45118 6.84171 6.64645 6.64645Z"
              fill="currentColor" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.3536 6.64645C17.5488 6.84171 17.5488 7.15829 17.3536 7.35355L7.35355 17.3536C7.15829 17.5488 6.84171 17.5488 6.64645 17.3536C6.45118 17.1583 6.45118 16.8417 6.64645 16.6464L16.6464 6.64645C16.8417 6.45118 17.1583 6.45118 17.3536 6.64645Z"
              fill="currentColor" />
          </svg>
        </span>
      </div>
      <div>
        <g-button
          data-test="editor-btn-after-publish"
          button-classes="w-full text-center justify-center"
          @click="handleViewLive">
          <template v-if="disabledViewLive">Preview template on shopify</template>
          <template v-else>View live page</template>
        </g-button>
      </div>
    </div>
  </g-modal>
</template>
