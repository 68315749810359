<script lang="ts" setup>
import GBaseIcon from '../../base/GBaseIcon.vue';

type Props = {
  hasUndo?: boolean;
  hasRedo?: boolean;
};

const emits = defineEmits<{
  (e: 'undo'): void;
  (e: 'redo'): void;
}>();
const props = withDefaults(defineProps<Props>(), {
  hasUndo: false,
  hasRedo: false,
});

const handleUndo = () => {
  if (props.hasUndo) {
    emits('undo');
  }
};

const handleRedo = () => {
  if (props.hasRedo) {
    emits('redo');
  }
};
</script>

<template>
  <div class="inline-flex">
    <g-tooltip placement="bottom-center">
      <div
        data-test="editor-btn-undo"
        class="rounded-medium mr-4 flex h-40 w-40 select-none items-center justify-center transition-colors duration-200 hover:bg-neutral-600"
        :class="
          hasUndo ? 'text-dark-high cursor-pointer' : 'text-dark-disabled cursor-not-allowed hover:bg-transparent'
        "
        @click="handleUndo">
        <span class="inline-flex">
          <g-base-icon name="undo" viewBox="0 0 20 20" width="20" height="20"></g-base-icon>
        </span>
      </div>
      <template #content>
        <span>Undo &nbsp; ⌘+Z</span>
      </template>
    </g-tooltip>
    <g-tooltip placement="bottom-center">
      <div
        data-test="editor-btn-redo"
        class="rounded-medium flex h-40 w-40 select-none items-center justify-center transition-colors duration-200 hover:bg-neutral-600"
        :class="
          hasRedo ? 'text-dark-high cursor-pointer' : 'text-dark-disabled cursor-not-allowed hover:bg-transparent'
        "
        @click="handleRedo">
        <span class="inline-flex">
          <g-base-icon name="redo" viewBox="0 0 20 20" width="20" height="20"></g-base-icon>
        </span>
      </div>
      <template #content>
        <span>Redo &nbsp; ⌘+Shift+Z</span>
      </template>
    </g-tooltip>
  </div>
</template>
