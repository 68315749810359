<script lang="ts" setup>
import GDropdown from '../../elements/Dropdown.vue';
import GTooltip from '../../base/GTooltip.vue';
import { computed } from 'vue';

type Device = {
  label?: string;
  id: string;
  icon?: string;
};

type Props = {
  idSelected: string;
  devices: Device[];
};

const emits = defineEmits<{
  (e: 'select', id?: string): void;
}>();

const props = defineProps<Props>();
const currentDevice = computed(() => props.devices.find((d) => d.id === props.idSelected));
const onSelected = (deviceId: string, close: Function) => {
  emits('select', deviceId);
  close();
};
</script>

<template>
  <div class="inline-flex gap-4">
    <g-dropdown
      class="ls:hidden"
      trigger="click"
      placement=""
      class-popover="rounded-none !ring-0 !right-[calc(50%_-_60px)] left-inherit">
      <template #default="{ isOpening }">
        <div
          class="rounded-medium after:rounded-b-medium relative flex h-40 w-[68px] cursor-pointer items-center justify-center after:absolute after:bottom-0 after:w-full after:border-b-2 after:border-transparent after:content-[''] hover:bg-neutral-600">
          <span class="inline-flex" v-html="currentDevice?.icon"></span>
          <button class="pl-8">
            <svg
              v-if="isOpening"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.64645 9.64645C6.84171 9.45118 7.15829 9.45118 7.35355 9.64645L12 14.2929L16.6464 9.64645C16.8417 9.45118 17.1583 9.45118 17.3536 9.64645C17.5488 9.84171 17.5488 10.1583 17.3536 10.3536L12.3536 15.3536C12.1583 15.5488 11.8417 15.5488 11.6464 15.3536L6.64645 10.3536C6.45118 10.1583 6.45118 9.84171 6.64645 9.64645Z"
                fill="white" />
            </svg>
            <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.6464 9.64645C11.8417 9.45118 12.1583 9.45118 12.3536 9.64645L17.3536 14.6464C17.5488 14.8417 17.5488 15.1583 17.3536 15.3536C17.1583 15.5488 16.8417 15.5488 16.6464 15.3536L12 10.7071L7.35355 15.3536C7.15829 15.5488 6.84171 15.5488 6.64645 15.3536C6.45118 15.1583 6.45118 14.8417 6.64645 14.6464L11.6464 9.64645Z"
                fill="white" />
            </svg>
          </button>
        </div>
      </template>
      <template #content="{ close }">
        <div class="cursor-pointer p-8">
          <div
            v-for="device in devices"
            :key="device.id"
            class="rounded-medium flex w-[104px] items-center p-8 hover:bg-white/20"
            @click="onSelected(device.id, close)">
            <span class="flex pr-[9.11px] pl-[1.1px]">
              <span
                v-if="idSelected !== device.id"
                class="flex h-16 w-16 items-center justify-center"
                v-html="device.icon"></span>
              <span v-else>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.3981 4.24093C13.5933 4.43619 13.5933 4.75277 13.3981 4.94804L6.10551 12.2406C6.01174 12.3344 5.88456 12.3871 5.75195 12.3871C5.61934 12.3871 5.49216 12.3344 5.39839 12.2406L2.60152 9.44361C2.40626 9.24834 2.40627 8.93176 2.60153 8.7365C2.7968 8.54124 3.11338 8.54125 3.30864 8.73652L5.75196 11.1799L12.691 4.24093C12.8862 4.04567 13.2028 4.04567 13.3981 4.24093Z"
                    fill="#00C853" />
                </svg>
              </span>
            </span>
            <span class="text-12"> {{ device.label }} </span>
          </div>
        </div>
      </template>
    </g-dropdown>
    <g-tooltip v-for="device in devices" :key="device.id" class="max-ls:hidden" placement="bottom">
      <div
        class="rounded-medium after:rounded-b-medium relative flex h-40 w-40 cursor-pointer items-center justify-center after:absolute after:bottom-0 after:w-full after:border-b-2 after:border-transparent after:content-[''] hover:bg-white/20"
        :class="{
          'bg-dark-300 text-dark-high': idSelected === device.id,
          'text-dark-low': idSelected !== device.id,
        }"
        :data-test="`editor-topbar-screen-${device.id}`"
        @click="emits('select', device.id)">
        <span class="inline-flex" v-html="device.icon"></span>
      </div>
      <template #content
        ><span>{{ device.label }}</span></template
      >
    </g-tooltip>
  </div>
</template>
